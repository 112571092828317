<template>
  <div @contextmenu="this.setContextIdWidget" :style="{ height: this.widgetHeight + 'px',overflow:'auto',background:'var(--widgetBg)' }" class="shadow-lg">
    <p class="hidden">{{ this.darkDark }}</p>
    <table-lite
      @dblclick="openConfig"
      :has-checkbox="true"
      :is-loading="table.isLoading"
      :is-re-search="table.isReSearch"
      :columns="table.columns"
      :rows="table.rows"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      :messages="table.messages"
      :page-size="pageSize"
      :color="color"
      :background="background"
      :height="widgetHeight"
      :width="widgetWidth"
      :headColor="headColor"
      :headBackground="headBackground"
      :headFontSize="headFontSize"
      :tableConfiguration="tableConfiguration"
      :tableId="id"
      :widgetPropertyIndex="widgetPropertyIndex"
      :alertColor="newAlertColor"
      @do-search="doSearch"
      @is-finished="tableLoadingFinish"
      @return-checked-rows="updateCheckedRows"
    ></table-lite>
  </div>
</template>

<script>
import TableLite from "./DataTableLibrary";
let id;
import apiService from "../../../api/services";
export default {
  name: "DataTableWidget",

  components: {
    TableLite,
  },

  props: {
    widgetId: String,
    displayId: String,
    alertColor: String,
    blinkAlert: Boolean,
    id: String,
    widgetWidth: Number,
    widgetHeight: Number,
    openConfig: Function,
    headColor: String,
    headBackground: String,
    headFontSize: Number,
    color: String,
    background: String,
    getTableColumns: Function,
    tableConfiguration: {},
    tableData: {
      default: {
        isLoading: false,
        isReSearch: false,
        columns: [
          
        ],
        rows: [
          
        ],
        sortable: {
          order: "name",
          sort: "asc",
        },
      },
    },
    unit:{
      type:String,
      default:'ft'
    },
    unit_conversion_factor:{
      type:[String,Number],
      default:''
    }
  },
  computed: {
    darkDark() {
      // if (this.$store.state.rect.darkmode) {
      //   this.$store.dispatch("rect/changeBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "#000000",
      //   });
      //   this.$store.dispatch("rect/changeColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "#FFFFFF",
      //   });
      //   this.$store.dispatch("rect/changeHeadBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "#111111",
      //   });
      //   this.$store.dispatch("rect/changeHeadColor", {
      //   displayId: this.displayId,
      //   widgetId: this.widgetId,
      //   value: "#FFFFFF"
      // });
      // } else {
      //   // this.borderColor = "#000000";
      //   this.$store.dispatch("rect/changeBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "#FFFFFF",
      //   });
      //   this.$store.dispatch("rect/changeHeadBackgroundColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "#B0B0B0",
      //   });
      //   this.$store.dispatch("rect/changeColor", {
      //     displayId: this.displayId,
      //     widgetId: this.widgetId,
      //     value: "#000000",
      //   });
      //   this.$store.dispatch("rect/changeHeadColor", {
      //   displayId: this.displayId,
      //   widgetId: this.widgetId,
      //   value: "#000000"
      // });
      // }
      return this.$store.state.rect.darkmode;
    },
  },
  async beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.widgetPropertyIndex = i;
      }
      //
      //   "faaaaaaaaaaaaaaaaa",
      //   this.$store.state.rect.rects[this.widgetPropertyIndex]
      // );
    }
  },
  beforeUnmount() {
    try {
      clearInterval(this.loadTableInterval);
    } catch (error) {}
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.logId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;

      this.status = dtls.status;
      clearInterval(this.loadTableInterval);
      if (this.status == "UPDATING")
        this.loadTableInterval = setInterval(() => {
          this.loadTable(dtls);
        }, 60000);

      this.loadTable(dtls);
    }

    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          if (mutation.payload.display == this.displayId) {
            this.wellId = mutation.payload.wellId;
            this.wellboreId = mutation.payload.wellboreId;
            this.logId = mutation.payload.logId;
            this.wellboreState = mutation.payload.wellboreState;
            this.logType = mutation.payload.logType;
            this.table = mutation.payload.table;
            this.status = mutation.payload.status;

            console.log("well details data table: sd", mutation.payload);
            clearInterval(this.loadTableInterval);
            if (this.status == "UPDATING")
              this.loadTableInterval = setInterval(() => {
                this.loadTable(mutation.payload);
              }, 60000);
            this.loadTable(mutation.payload);
          }
          break;
      }
    });
  },
  data() {
    return {
      newAlertColor: "#ffffff",
      isBlinkAlert: false,
      columns: [],
      rows: [],
      tableConfig: {
        color: "",
        background: "#0d0d0d",
        headColor: "#cccccc",
        headBackground: "#ffffff",
      },
      pageSize: 10,
      backupTableRows: [],
      table: this.tableData,
      widgetPropertyIndex: -1,
    };
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async loadTable(dtls) {
      let dataForTable = await apiService.WellServices.getDataTableData({
        well_id: dtls.wellId,
        wellbore_name: dtls.wellboreId,
      });

      let sequence = [
        "md",
        "tvd",
        "azi",
        "incl",
        "dispns",
        "dispew",
        "tvd",
        "dls",
        "gtf",
        "mtf",
      ];
      let tempCol = {};
      dataForTable.data.columns.map((col) => {
        tempCol[col.field] = col;
      });
      let column = [];
      sequence.map((ele) => {
        if (tempCol[ele]) {
          column.push(tempCol[ele]);
          delete tempCol[ele];
        }
      });
      // for (let key in tempCol) {
      //   column.push(tempCol[key]);
      // }
      this.table = {
        isLoading: false,
        isReSearch: false,
        columns: column,
        rows: dataForTable.data.rows,
        sortable: {
          order: "name",
          sort: "asc",
        },
      };
    },
    searchHandler(e) {
      let value = e.target.value;
      let newRows = JSON.parse(JSON.stringify(this.table.rows));
      this.table.rows = this.backupTableRows.filter((row) =>
        row.dept.toString().includes(value)
      );

      //
    },

    doSearch(offset, limit, order, sort) {
      // sortFunction
      // this.table.isLoading = true;
      this.table.isReSearch = offset == undefined ? true : false;
      let compare = (a, b) => {
        if (a[order] < b[order]) {
          return sort == "desc" ? 1 : -1;
        }
        if (a[order] > b[order]) {
          return sort == "desc" ? -1 : 1;
        }
        return 0;
      };
      this.backupTableRows.sort(compare);
      //
      let newRows = JSON.parse(JSON.stringify(this.backupTableRows));
      // let paginatedTableRows = newRows.slice(
      //   offset,
      //   parseInt(limit) + parseInt(offset)
      // );
      this.table.rows = newRows;

      this.table.sortable.order = order;
      this.table.sortable.sort = sort;
      // this.table.isLoading = false;
    },

    tableLoadingFinish(elements) {
      if (this.table) {
        // this.table.isLoading = false;
        Array.prototype.forEach.call(elements, function (element) {
          if (element.classList.contains("name-btn")) {
            element.addEventListener("click", function () {
              // do your click event
              //
            });
          }
          if (element.classList.contains("quick-btn")) {
            // do your click event
            element.addEventListener("click", function () {});
          }
        });
      }
    },
  },
  watch: {
    blinkAlert: function (newVal) {
      this.isBlinkAlert = newVal;
      if (this.isBlinkAlert == false) {
        for (var i = 1; i < id; i++) {
          window.clearInterval(i);
        }
      }
    },
    alertColor: async function (newVal, oldVal) {
      let i = 0;
      if (this.isBlinkAlert) {
        id = setInterval(() => {
          let color = ["white", newVal];
          i = (i + 1) % color.length;
          this.newAlertColor = color[i];
          if (!this.isBlinkAlert) {
            clearInterval(id);
          }
        }, 1000);
      } else {
        this.newAlertColor = newVal;
      }
    },
    unit(val){
      if(val && val!=""){
       const sequence = [
        // "md",
        // "tvd",
        // "dispns",
        // "dispew"
        "md",
        "tvd",
        "azi",
        "incl",
        "dispns",
        "dispew",
        "dls",
        "gtf",
        "mtf",
      ];
      sequence.forEach((s,i)=>{
        let colFound = this.table.columns.find(c=>c.field==s);
        if((colFound)&&(colFound.field=='md' || colFound.field=='tvd' || colFound.field=='dispns' || colFound.field=='dispew')){
          this.table.columns[i].label=`${s} ${val}`
        }
      })   
      }
    },
    unit_conversion_factor(val){
      if(val && val!=""){
       const mappedData = this.table.rows.map(e=>{
          return{...e,md:+e.md * val,tvd:(+e.tvd * val).toFixed(1),dispns:(+e.dispns * val).toFixed(1),dispew:(+e.dispew * val).toFixed(1)}
        });
        this.table.rows = mappedData;
      }
    },
  },

  emits: ["toggle-madal"],
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 3px !important;
}
</style>
